import {gql} from 'apollo-angular';
import {ImageFragment} from './framents/ImageFragment';
import {TourPreviewFragment} from './framents/TourPreviewFragment';
import {ObjectPreviewFragment} from './framents/ObjectPreviewFragment';

export const GET_HOME_PAGE_CONTENT = gql`
  query GetHomePageContent {
    homePageSettings {
      acfHome {
        introAudioFileDe {
          mediaItemUrl
        }
        introAudioFileEn {
          mediaItemUrl
        }
        introDurationDe
        introDurationEn
        introHeadlineDe
        introHeadlineEn
        introIllustration {
          ...imageFields
        }
        introPreviewImage {
          ...imageFields
        }
        introSubHeadlineDe
        introSubHeadlineEn
        introTranscriptDe
        introTranscriptEn
        featuredObjects {
          ... on Object {
            ...objectPreviewFields
          }
        }
        featuredObjectsHeadlineDe
        featuredObjectsHeadlineEn
        featuredTours {
          ... on Tour {
            ...tourPreviewFields
          }
        }
        featuredToursHeadlineDe
        featuredToursHeadlineEn
        popularObjects {
          ... on Object {
            ...objectPreviewFields
          }
        }
        voicesHeadlineDe
        voicesHeadlineEn
        voicesTours {
          ... on Tour {
            ...tourPreviewFields
          }
        }
        popularObjectsHeadlineDe
        popularObjectsHeadlineEn
        outroSubHeadlineDe
        outroSubHeadlineEn
        outroHeadlineDe
        outroHeadlineEn
        outroLink {
          title
          url
          target
        }
        outroIllustration {
          ...imageFields
        }
        outroColor
      }
    }
  }
  ${ImageFragment}
  ${ObjectPreviewFragment}
${TourPreviewFragment}`;
