import {Injectable} from '@angular/core';
import {ImageService} from './image.service';
import {Observable} from 'rxjs';
import {DataService} from './data.service';
import {TranslateService} from '@ngx-translate/core';
import {YoastSeoService} from './yoast-seo.service';
import {Title} from '@angular/platform-browser';
import {Apollo} from 'apollo-angular';

import {GET_CONTENT_BY_ID, GET_TOUR_BY_ID} from '../queries/';
import {GetContentById} from '../queries/__generated__/GetContentById';
import {ContentBase} from '../interfaces/content-base';
import {MakConstants} from '../shared/MakConstants';
import {GetTourById} from '../queries/__generated__/GetTourById';
import {Tour} from '../interfaces/tour';
import {GET_CONTENT_WITH_LOCATION} from '../queries/GET_CONTENT_WITH_LOCATION';
import {ObjectDetailsService} from './object-details.service';
import {GET_POIS} from '../queries/GET_POIS';
import {Poi} from '../interfaces/poi';
import {GetPOIs} from '../queries/__generated__/GetPOIs';
import {ObjectDetails} from '../interfaces/object-details';
import {CardMapperService} from './card-mapper.service';
import {PoiMapperService} from './poi-mapper.service';
import {TourMapperService} from './tour-mapper.service';
import {GET_CONTENT_BY_PATTERN} from '../queries/GET_CONTENT_BY_PATTERN';
import {GetContentByPattern} from '../queries/__generated__/GetContentByPattern';
import {take} from 'rxjs/operators';
import {GET_CODE_BY_PATTERN} from '../queries/GET_CODE_BY_PATTERN';
import {GetCodeByPattern} from '../queries/__generated__/GetCodeByPattern';
import {GET_PAGE_BY_ID} from '../queries/GET_PAGE_BY_ID';
import {GetPageById} from '../queries/__generated__/GetPageById';
import {SimplePage} from '../interfaces/simple-page';
import {HomePage} from '../interfaces/home-page';
import {GetHomePageContent} from '../queries/__generated__/GetHomePageContent';
import {GET_HOME_PAGE_CONTENT} from '../queries/GET_HOME_PAGE_CONTENT';
import {Playlist} from '../interfaces/playlist';
import {PosterService} from './poster.service';
import {GET_TOUR_PREVIEWS} from '../queries/GET_TOUR_PREVIEWS';
import {TourPreview} from '../interfaces/tour-preview';
import {GetTourPreviews} from '../queries/__generated__/GetTourPreviews';
import {GET_NOTIFICATIONS_BY_DATE} from '../queries/GET_NOTIFICATIONS_BY_DATE';
import {Notification} from '../interfaces/notification';
import {GetNotificationsByDate} from '../queries/__generated__/GetNotificationsByDate';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private image: ImageService,
    private data: DataService,
    private yoast: YoastSeoService,
    private translate: TranslateService,
    private titleService: Title,
    private apollo: Apollo,
    private objectDetailsService: ObjectDetailsService,
    private cardMapper: CardMapperService,
    private poiMapper: PoiMapperService,
    private tourMapper: TourMapperService,
    private posterService: PosterService
  ) { }

  getContentByPattern(pattern: string): Observable<ContentBase> {
    return new Observable(observer => {

      const content: ContentBase = {
        id: 0,
        title: '',
        type: 'undefined',
        isLabAppLink: false,
        labAppLink: '',
        fields: {
          title: '',
          audioDescription: ''
        }
      };

      this.apollo
        .query<GetContentByPattern>({
          query: GET_CONTENT_BY_PATTERN,
          variables: {
            pattern
          }
        })
        .subscribe(({data, loading}) => {

          if (data.contentNodes?.edges?.length) {
            data.contentNodes?.edges?.forEach(edge => {


              if (edge?.node?.__typename === 'Object') {
                content.id = edge?.node?.databaseId ?? 0;
                content.title = edge?.node?.title ?? '';
                content.type = MakConstants.contentTypes.object;
                content.fields = this.objectDetailsService.mapObjectDetails(edge?.node);

                observer.next(content);
                observer.complete();
                return;
              }

              if (edge?.node?.__typename === 'Tour') {
                content.id = edge?.node?.databaseId ?? 0;
                content.title = edge?.node?.title ?? '';
                content.type = MakConstants.contentTypes.tour;

                observer.next(content);
                observer.complete();
                return;
              }

              if (edge?.node?.__typename === 'Resource') {
                content.id = edge?.node?.databaseId ?? 0;
                content.title = edge?.node?.title ?? '';
                content.type = MakConstants.contentTypes.resource;

                observer.next(content);
                observer.complete();
                return;
              }

            });
          } else {

            this.apollo
              .query<GetCodeByPattern>({
                query: GET_CODE_BY_PATTERN,
                variables: {
                  pattern
                }
              })
              // tslint:disable-next-line:no-shadowed-variable
              .subscribe(({data, loading}) => {

                if (data.contentNodes?.edges?.length) {
                  data.contentNodes?.edges?.forEach(edge => {

                    if (edge?.node?.__typename === 'Code') {
                      content.id = edge?.node?.databaseId ?? 0;
                      content.title = edge?.node?.title ?? '';
                      content.type = MakConstants.contentTypes.code;
                      content.isLabAppLink = edge?.node?.acfCode?.isLabAppCode ?? false;
                      content.labAppLink = edge?.node?.acfCode?.labAppLink ?? '';
                    }
                  });

                  observer.next(content);
                  observer.complete();
                  return;

                } else {
                  console.warn('Did not find entity with id: ' + pattern);
                  observer.next(content);
                  observer.complete();
                  return;
                }
              });

          }


        });
    });
  }

  getContentById(databaseId: number): Observable<ContentBase> {
    return new Observable(observer => {

      const content: ContentBase = {
        id: databaseId,
        title: '',
        type: 'undefined',
        isLabAppLink: false,
        labAppLink: '',
        fields: {
          title: '',
          audioDescription: ''
        }
      };

      this.apollo
        .query<GetContentById>({
          query: GET_CONTENT_BY_ID,
          variables: {
            databaseId
          }
        })
        .subscribe(({data, loading}) => {

          if (data.contentNodes?.edges?.length) {
            data.contentNodes?.edges?.forEach(edge => {

              if (edge?.node?.__typename === 'Object') {
                content.title = edge?.node?.title ?? '';
                content.type = MakConstants.contentTypes.object;
                content.fields = this.objectDetailsService.mapObjectDetails(edge?.node);
              }

              if (edge?.node?.__typename === 'Tour') {
                content.title = edge?.node?.title ?? '';
                content.type = MakConstants.contentTypes.tour;
              }

              if (edge?.node?.__typename === 'Resource') {
                content.title = edge?.node?.title ?? '';
                content.type = MakConstants.contentTypes.resource;
              }

            });
          } else {
            console.warn('Did not find entity with id: ' + databaseId);
          }

          observer.next(content);
          observer.complete();

        });
    });
  }

  getContentWithLocation(): Observable<any> {
    return new Observable(observer => {

      const objects: any[] = new Array(0);

      this.apollo
        .query<GetContentById>({
          query: GET_CONTENT_WITH_LOCATION
        })
        .subscribe(({data, loading}) => {

          if (data.contentNodes?.edges?.length) {
            data.contentNodes?.edges?.forEach(edge => {

              if (edge?.node?.__typename === 'Object') {
                const mappedObject = this.objectDetailsService.mapObjectDetails(edge?.node);
                if (mappedObject) {
                  objects.push(mappedObject);
                }
              }

              if (edge?.node?.__typename === 'Tour') {
              }

              if (edge?.node?.__typename === 'Resource') {
              }

            });
          } else {
            console.warn('Did not find entity with location.');
          }



          observer.next(objects);
          observer.complete();

        });
    });
  }

  getTours(): Observable<TourPreview[]> {
    return new Observable<TourPreview[]>(observer => {
      this.apollo
        .query<GetTourPreviews>({
          query: GET_TOUR_PREVIEWS
        })
        .subscribe(({data, loading}) => {
          console.log(data);

          const tours: TourPreview[] = new Array(0);

          data.tours?.nodes?.forEach(tour => {
            tours.push(this.tourMapper.mapTourPreviewData(tour, tour?.databaseId));
          });

          observer.next(tours);
          observer.complete();
        }, (err) => {
          console.log(err);
        });
    });
  }

  getTourById(tourId: number): Observable<Tour> {
    return new Observable<Tour>(observer => {
      this.apollo
        .query<GetTourById>({
          query: GET_TOUR_BY_ID,
          variables: {
            tourId
          }
        })
        .subscribe(({data, loading}) => {

          if (data.contentNodes?.nodes?.length && data.contentNodes?.nodes[0]?.__typename === 'Tour') {
            const tourNode = data.contentNodes?.nodes[0];
            observer.next(this.tourMapper.mapTourData(tourNode, tourId));
          }

          observer.complete();
        });
    });
  }

  getPois(): Observable<Poi[]> {
    return new Observable<Poi[]>(observer => {
      this.apollo
        .query<GetPOIs>({
          query: GET_POIS
        })
        .subscribe(({data, loading}) => {

          const pois: Poi[] = new Array(0);

          data.pois?.nodes?.forEach(poi => {
            pois.push(this.poiMapper.mapPoiData(poi));
          });

          observer.next(pois);
          observer.complete();
        });
    });
  }

  /**
   * Return ObjectDetails by its ID
   * @param id number
   * @param parentTourID number (!) MAY BE REDUNDANT
   */

  getObjectDetailsByID(id: number, parentTourID: number = -1): Promise<ObjectDetails | null> {
    return new Promise(async (resolve) => {
      if (parentTourID !== -1) {
        this.getTourById(parentTourID).pipe(take(1)).subscribe((response: Tour) => {
          const foundedObjects = response.cards.filter((card) => card.object.id === id);
          if (foundedObjects.length) {
            resolve(foundedObjects[0].object);
          } else {
            resolve(null);
          }
        });
      } else {
        this.getContentById(id).pipe(take(1)).subscribe((response) => {
          if (response) {
            resolve(response.fields);
          } else {
            resolve(null);
          }
        });
      }
    });
  }

  getPageById(postId: number): Observable<SimplePage> {
    return new Observable<SimplePage>(observer => {
      this.apollo
        .query<GetPageById>({
          query: GET_PAGE_BY_ID,
          variables: {
            postId
          }
        })
        .subscribe(({data, loading}) => {

          const pageContent: SimplePage = {
            de: {
              title: '',
              content: ''
            },
            en: {
              title: '',
              content: ''
            }
          };

          if (data.pageBy) {
            pageContent.de.title = data.pageBy.title ?? '';
            pageContent.de.content = data.pageBy.acfPage?.contentDe ?? '';
            pageContent.en.title = data.pageBy.acfPage?.titleEn ?? '';
            pageContent.en.content = data.pageBy.acfPage?.contentEn ?? '';
          }

          observer.next(pageContent);
          observer.complete();
        });
    });
  }

  getHomePageContent(): Observable<HomePage> {
    return new Observable<HomePage>(observer => {
      this.apollo
        .query<GetHomePageContent>({
          query: GET_HOME_PAGE_CONTENT
        })
        .subscribe(({data, loading}) => {

          const pageContent: HomePage = {
            de: {
              introSubHeadline: '',
              introHeadline: '',
              featuredToursHeadline: '',
              voicesHeadline: '',
              featuredObjectsHeadline: '',
              popularObjectsHeadline: ''
            },
            en: {
              introSubHeadline: '',
              introHeadline: '',
              featuredToursHeadline: '',
              voicesHeadline: '',
              featuredObjectsHeadline: '',
              popularObjectsHeadline: ''
            },
            introIllustration: undefined,
            featuredTours: [],
            voicesTours: [],
            featuredObjects: [],
            popularObjects: [],
            introPlaylist: undefined,
            outroPoster: this.posterService.getEmptyPoster()
          };

          if (data.homePageSettings) {

            pageContent.de.introSubHeadline = data.homePageSettings.acfHome?.introSubHeadlineDe ?? '';
            pageContent.en.introSubHeadline = data.homePageSettings.acfHome?.introSubHeadlineEn ?? '';
            pageContent.de.introHeadline = data.homePageSettings.acfHome?.introHeadlineDe ?? '';
            pageContent.en.introHeadline = data.homePageSettings.acfHome?.introHeadlineEn ?? '';

            const audioResource: Playlist = {
              sprite: {
                de: data.homePageSettings.acfHome?.introAudioFileDe?.mediaItemUrl ?? '',
                en: data.homePageSettings.acfHome?.introAudioFileEn?.mediaItemUrl ?? '',
              },
              items: [
                {
                  objectDetailsId: -1,
                  parentTourId: -1,
                  audioId: '-1', // TODO it is always undefined, can be issue
                  thumbnail: this.image.parseAcfImage(data.homePageSettings.acfHome?.introPreviewImage),
                  de: {
                    title: pageContent.de.introHeadline,
                    timestamp: 0,
                    duration: data.homePageSettings.acfHome?.introDurationDe ?? 0,
                    audioTranscript: data.homePageSettings.acfHome?.introTranscriptDe ?? ''
                  },
                  en: {
                    title: pageContent.en.introHeadline,
                    timestamp: 0,
                    duration: data.homePageSettings.acfHome?.introDurationEn ?? 0,
                    audioTranscript: data.homePageSettings.acfHome?.introTranscriptEn ?? ''
                  }
                }
              ]
            };

            pageContent.introPlaylist = audioResource;

            pageContent.de.featuredToursHeadline = data.homePageSettings.acfHome?.featuredToursHeadlineDe ?? '';
            pageContent.en.featuredToursHeadline = data.homePageSettings.acfHome?.featuredToursHeadlineEn ?? '';

            pageContent.de.featuredObjectsHeadline = data.homePageSettings.acfHome?.featuredObjectsHeadlineDe ?? '';
            pageContent.en.featuredObjectsHeadline = data.homePageSettings.acfHome?.featuredObjectsHeadlineEn ?? '';

            pageContent.de.popularObjectsHeadline = data.homePageSettings.acfHome?.popularObjectsHeadlineDe ?? '';
            pageContent.en.popularObjectsHeadline = data.homePageSettings.acfHome?.popularObjectsHeadlineEn ?? '';

            pageContent.introIllustration = this.image.parseAcfImage(data.homePageSettings.acfHome?.introIllustration);

            pageContent.de.voicesHeadline = data.homePageSettings.acfHome?.voicesHeadlineDe ?? '';
            pageContent.en.voicesHeadline = data.homePageSettings.acfHome?.voicesHeadlineEn ?? '';


            // Featured Tours
            if (data.homePageSettings.acfHome?.featuredTours?.length) {
              data.homePageSettings.acfHome?.featuredTours.forEach(rawTour => {
                pageContent.featuredTours.push(this.tourMapper.mapTourPreviewData(rawTour));
              });
            }

            // Voices Tours
            if (data.homePageSettings.acfHome?.voicesTours?.length) {
              data.homePageSettings.acfHome?.voicesTours.forEach(rawTour => {
                pageContent.voicesTours.push(this.tourMapper.mapTourPreviewData(rawTour));
              });
            }

            // Featured Objects
            if (data.homePageSettings.acfHome?.featuredObjects?.length) {
              data.homePageSettings.acfHome?.featuredObjects.forEach(rawObject => {
                pageContent.featuredObjects.push(this.objectDetailsService.mapObjectDetails(rawObject));
              });
            }

            // Popular Objects
            if (data.homePageSettings.acfHome?.popularObjects?.length) {
              data.homePageSettings.acfHome?.popularObjects.forEach(rawObject => {
                pageContent.popularObjects.push(this.objectDetailsService.mapObjectDetails(rawObject));
              });
            }

            // Outro

            pageContent.outroPoster = {
              de: {
                title: data.homePageSettings.acfHome?.outroSubHeadlineDe ?? '',
                text: data.homePageSettings.acfHome?.outroHeadlineDe ?? '',
                linkTitle: data.homePageSettings.acfHome?.outroLink?.title ?? '',
                linkUrl: data.homePageSettings.acfHome?.outroLink?.url ?? ''
              },
              en: {
                title: data.homePageSettings.acfHome?.outroSubHeadlineEn ?? '',
                text: data.homePageSettings.acfHome?.outroHeadlineEn ?? '',
                linkTitle: data.homePageSettings.acfHome?.outroLink?.title ?? '',
                linkUrl: data.homePageSettings.acfHome?.outroLink?.url ?? ''
              },
              image: this.image.parseAcfImage(data.homePageSettings.acfHome?.outroIllustration),
              colorScheme: 'blue',
              isLinkExternal: (data.homePageSettings.acfHome?.outroLink?.url ?? '').indexOf('http') !== -1,
              buttonType: 'icon',
              icon: 'chevron-right'
            };

            if ( data.homePageSettings.acfHome?.outroColor) {
              if ( data.homePageSettings.acfHome?.outroColor === 'beige' ||  data.homePageSettings.acfHome?.outroColor === 'yellow') {
                pageContent.outroPoster.colorScheme = data.homePageSettings.acfHome?.outroColor ?? 'beige';
              }
            }
          }

          observer.next(pageContent);
          observer.complete();
        });
    });
  }


  getTodaysNotifications(): Observable<Notification[]> {

    console.log(this.getApiFormattedDate());

    return new Observable<Notification[]>(observer => {
      this.apollo
        .query<GetNotificationsByDate>({
          query: GET_NOTIFICATIONS_BY_DATE,
          variables: {
            notificationDate: this.getApiFormattedDate()
          }
        })
        .subscribe(({data, loading}) => {

          const notifications: Notification[] = new Array(0);

          data.contentNodes?.edges?.forEach(rawNotification => {

            if (rawNotification?.node?.__typename === 'Notification') {

              const node = rawNotification?.node;

              const notification: Notification = {
                id: node?.databaseId ?? 0,
                image: node?.acfNotification?.image?.srcSet ?? '',
                de: {
                  tag: node?.acfNotification?.tagDe ?? '',
                  linkIsExtern: node?.acfNotification?.linkDe?.url === '_blank',
                  text: node?.acfNotification?.textDe ?? '',
                  linkUrl: node?.acfNotification?.linkDe?.url ?? '',
                  linkTitle: node?.acfNotification?.linkDe?.title ?? ''
                },
                en: {
                  tag: node?.acfNotification?.tagEn ?? '',
                  linkIsExtern: node?.acfNotification?.linkEn?.url === '_blank',
                  text: node?.acfNotification?.textEn ?? '',
                  linkUrl: node?.acfNotification?.linkEn?.url ?? '',
                  linkTitle: node?.acfNotification?.linkEn?.title ?? ''
                }
              };

              notifications.push(notification);

            }

          });

          observer.next(notifications);
          observer.complete();
        }, (err) => {
          console.log(err);
        });
    });
  }

  getApiFormattedDate(): string {
    const date = new Date();

    const mm = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();

    return [date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('');
  }


}
